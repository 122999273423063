import React, {useState} from "react";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import {Box, Button, Grid, TextField, Zoom} from '@mui/material';
import AppService from "../../service/AppService";
import Swal from "sweetalert2";
import {parseError} from "../../config/react-routing";
import Loader from "../general/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import MaterialReactTable from "../quote-request/MaterialReactTable";
import DialogContent from "@mui/material/DialogContent";
import ReactJson from "react18-json-view";
import Dialog from "@mui/material/Dialog";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {Controller, useForm} from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";


export default function ThrowAwayQuoteRequestPage(props) {

    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const {execute, isLoading} = useFetchWithMsal(loginRequest);
    const [open, setOpen] = useState(false);
    const [jsonPayload, setJsonPayload] = useState(null);
    const [quoteInfoView, setQuoteInfoView] = useState(null);
    const handleOpenModal = (json, quoteInfo) => {
        if (json) {
            setJsonPayload(json);
            setQuoteInfoView(null);
        }
        if (quoteInfo) {
            setQuoteInfoView(quoteInfo);
            setJsonPayload(null);
        }
        setOpen(true);
    };

    const formatDate = (date) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        return new Date(date).toLocaleTimeString('en-US', options);
    };
    const handleClose = () => {
        setOpen(false);
        setJsonPayload(null);
        setQuoteInfoView(null);
    };

    const formatDateAndTimeCell = (cell) => {
        const date = new Date(cell.getValue());
        const formattedDate = date.toISOString().split('T')[0];
        const formattedTime = formatDate(date);

        return (
            <div>
                {formattedDate} <span style={{marginLeft: '8px'}}> {formattedTime}</span>
            </div>
        );
    };

    const formatDateAndTime = (date) => {
        const dateValue = new Date(date);
        const formattedDate = dateValue.toISOString().split('T')[0];
        const formattedTime = formatDate(dateValue);
        return `${formattedDate} ${formattedTime}`
    };

    const {
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
        reset
    } = useForm({
        defaultValues: {
            createdAfter: "",
            createdBefore: "",
            insured: "",
            streetAddress: "",
            county: "",
            effectiveDate: "",
            policyType: "",
            submissionType: "",
            agentLicense: "",
            agentName: "",
            agencyName: "",
            errorMessage: "",
            pageNumber: 0
        },
    });

    const headerColumns = [
        {
            header: '#',
            id: 'serialNumber',
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => ((getValues().pageNumber) * 25) + row.index + 1,
        },
        {
            header: "CREATED AT",
            accessorKey: "ranAt",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({cell}) => {
                return formatDateAndTimeCell(cell)
            },
        },
        {
            header: "QUOTE REQUEST",
            accessorKey: "quotePayload",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => {
                return (
                    <div style={{display: "flex", alignItems: "center", gap: "3.5rem"}}>
                        <FontAwesomeIcon icon={faEye} size="lg" style={{cursor: "pointer"}}
                                         onClick={() => handleOpenModal(row.original.quotePayload, null)}/>
                        <FontAwesomeIcon
                            icon={faDownload}
                            size="lg"
                            onClick={() => handleDownloadJSON('quote_request', row.original.quotePayload)}
                            style={{cursor: "pointer"}}
                        />
                    </div>)
            },
        },
        {
            header: "VALIDATION RESPONSE",
            accessorKey: "validationResponse",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => {
                return (
                    <div style={{display: "flex", alignItems: "center", gap: "3.5rem"}}>
                        <FontAwesomeIcon icon={faEye} size="lg" style={{cursor: "pointer"}}
                                         onClick={() => handleOpenModal(row.original.validationResponse, null)}/>
                        <FontAwesomeIcon
                            icon={faDownload}
                            size="lg"
                            onClick={() => handleDownloadJSON('validation_response', row.original.validationResponse)}
                            style={{cursor: "pointer"}}
                        />
                    </div>)
            },
        },
        {
            header: "QUOTE INFO",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({row}) => {
                return (
                    <div style={{display: "flex", alignItems: "center", gap: "3.5rem"}}>
                        <FontAwesomeIcon icon={faEye} size="lg" style={{cursor: "pointer"}}
                                         onClick={() => handleViewQuoteInfoData(row.original)}/>
                        <FontAwesomeIcon
                            icon={faDownload}
                            size="lg"
                            onClick={() => handleDownload(row.original)}
                            style={{cursor: "pointer"}}
                        />
                    </div>)
            },
        },
    ];

    const handleDownloadJSON = (name, data) => {
        const blob = new Blob([JSON.stringify(JSON.parse(data), null, 2)], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = name + ".json";
        link.click();
        URL.revokeObjectURL(url);
    }

    const getAgentInformation = async (payload) => {
        let agentInfo = {};
        return await AppService().getAgentInfo(
            execute,
            JSON.stringify({
                producerCode: payload.policyInfo?.producerCode,
                subProducerCode: payload.policyInfo?.subProducerCode
            })
        )
            .then((data) => {
                agentInfo = data;
                return agentInfo;
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get throw away results",
                    text: parseError(err),
                    showConfirmButton: true,
                });
            }).finally(() => {
                return agentInfo;
            });
    }
    const getDetails = async (criteria) => {
        let searchCriteria = Object.assign({}, criteria);
        searchCriteria.createdAfter = searchCriteria.createdAfter + " 00:00:00.000"
        searchCriteria.createdBefore =
            searchCriteria.createdBefore + " 23:59:59.000"
        searchCriteria.pageNumber = getValues().pageNumber;
        AppService().getThrowAwayQuotes(
            execute,
            JSON.stringify(searchCriteria)
        )
            .then((data) => {
                setTableData(data?.searchResult);
                setTotalRecords(data?.numOfRecords);
                setValue('pageNumber', data?.pageNumber);
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get throw away results",
                    text: parseError(err),
                    showConfirmButton: true,
                });
            });
    };

    const nextPage = async (newPage) => {
        setValue('pageNumber', newPage);
        setTableData([]);
        getDetails(getValues());
    };

    const handleSearch = (event) => {
        setTableData([]);
        getDetails(event);
    };

    const handleDownload = async (res) => {
        const response = JSON.parse(res.quotePayload);
        await getAgentInformation(response)
            .then(agentInfo => {
                const streetAddress = response?.policyInfo?.homeOwnersLine?.policyAddress;
                const responseData = [
                    {
                        "Created At": formatDateAndTime(res?.ranAt),
                        "Insured": `${response?.acctInfo?.acctHolder?.firstName} ${response?.acctInfo?.acctHolder?.lastName}`,
                        "Street Address": `${streetAddress?.address1} ${streetAddress?.address2 ? streetAddress?.address2 : ''} ${streetAddress?.city} ${streetAddress?.state} ${streetAddress?.zip}`,
                        "County": streetAddress?.county,
                        "Effective Date": response?.policyInfo?.effectiveDate,
                        "Policy Type": response?.policyInfo?.homeOwnersLine?.policyType,
                        "Submission Type": "New Business",
                        "Agent License": agentInfo?.agentLicense,
                        "Agent Name": agentInfo?.agentName,
                        "Agency Name": agentInfo?.agencyName,
                        "Error Message": JSON.parse(res?.validationResponse)?.errorMessagesOption1
                    },
                ];

                const worksheet = XLSX.utils.json_to_sheet(responseData);

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Quote Info");

                const excelBuffer = XLSX.write(workbook, {bookType: "xlsx", type: "array"});
                const blob = new Blob([excelBuffer], {type: "application/octet-stream"});
                saveAs(blob, "quote_info.xlsx");
            })

    };

    const handleViewQuoteInfoData = async (res) => {
        const response = JSON.parse(res.quotePayload);
         await getAgentInformation(response)
            .then(agentInfo => {
                const streetAddress = response?.policyInfo?.homeOwnersLine?.policyAddress;
                const responseData = [
                    {
                        "Created At": formatDateAndTime(res?.ranAt),
                        "Insured": `${response?.acctInfo?.acctHolder?.firstName} ${response?.acctInfo?.acctHolder?.lastName}`,
                        "Street Address": `${streetAddress?.address1} ${streetAddress?.address2 ? streetAddress?.address2 : ''} ${streetAddress?.city} ${streetAddress?.state} ${streetAddress?.zip}`,
                        "County": streetAddress?.county,
                        "Effective Date": response?.policyInfo?.effectiveDate,
                        "Policy Type": response?.policyInfo?.homeOwnersLine?.policyType,
                        "Submission Type": "New Business",
                        "Agent License": agentInfo?.agentLicense,
                        "Agent Name": agentInfo?.agentName,
                        "Agency Name": agentInfo?.agencyName,
                        "Error Message": JSON.parse(res?.validationResponse)?.errorMessagesOption1
                    },
                ];
                handleOpenModal(null, responseData);
                return responseData;
            });
    }

    function getDialog() {
        return <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Zoom}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent>
                {jsonPayload && <ReactJson displaySize="expanded" src={JSON.parse(jsonPayload)} collapsed={2}/>}
                {(quoteInfoView && quoteInfoView.length > 0) ? (<div>
                    <table border='1' style={{minWidth: 'auto', border: '1px solid black'}}>
                        <thead>
                        <tr>
                            {Object.keys(quoteInfoView[0]).map((key) => (
                                <th key={key}>
                                    {key}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {quoteInfoView.map((data, index) => (
                            <tr key={index}>
                                {Object.values(data).map((value, valueIndex) => (
                                    <td key={valueIndex} style={{minWidth: 'auto'}}>
                                        {typeof value === "object" && value !== null
                                            ? JSON.stringify(value)
                                            : value || "-"}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>) : (!jsonPayload && <div style={{textAlign: "center", padding: "20px"}}>
                    No data available
                </div>)}
            </DialogContent>
        </Dialog>;
    }

    return (
        <Box display="flex" flexDirection="column">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "22px",
                    fontWeight: 700,
                    marginBottom: "1rem",
                }}
            >
                Search Throw Away Quotes (Validation Failed Quotes):
            </div>
            <form onSubmit={handleSubmit(handleSearch)}>

                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <ControllerField
                        control={control}
                        name="createdAfter"
                        label="Created After"
                        rules={{required: "Created After date is required"}}
                        type="date"
                    />

                    <ControllerField
                        control={control}
                        name="createdBefore"
                        label="Created Before"
                        rules={{required: "Created Before date is required"}}
                        type="date"
                    />

                    <ControllerField control={control} name="insured" label="Insured"/>

                    <ControllerField
                        control={control}
                        name="streetAddress"
                        label="Street Address"
                    />
                    <ControllerField control={control} name="county" label="County"/>
                    <ControllerField
                        control={control}
                        name="effectiveDate"
                        label="Effective Date"
                        type="date"
                    />
                    <ControllerField
                        control={control}
                        name="policyType"
                        label="Policy Type"
                        select
                        options={[
                            {label: "HO3", value: "HO3"},
                            {label: "HO6", value: "HO6"},
                            {label: "DP1D", value: "DP1D"},
                            {label: "DP3D", value: "DP3D"},
                        ]}
                    />
                    <ControllerField
                        control={control}
                        name="submissionType"
                        label="Submission Type"
                        select
                        options={[
                            {label: "New Business", value: "newBusiness"},
                            {label: "Renewal", value: "renewal"},
                        ]}
                    />
                    <ControllerField
                        control={control}
                        name="agentLicense"
                        label="Agent License"
                    />
                    <ControllerField control={control} name="agentName" label="Agent Name"/>
                    <ControllerField control={control} name="agencyName" label="Agency Name"/>
                    <ControllerField control={control} name="errorMessage" label="Error Message (Pattern search)"/>
                </Grid>

                <Grid container flexDirection={"row-reverse"} rowSpacing={2} sx={{marginTop: 2}}>
                    <Grid item sx={{padding: 1}}>
                        <Button variant="contained" color="primary" type="button" onClick={reset}>
                            Reset
                        </Button>
                    </Grid>
                    <Grid item sx={{padding: 1}}>
                        <Button variant="contained" color="primary" type="submit">
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                }}
            >
                {isLoading && <Loader/>}
            </div>
            {getDialog()}
            {tableData &&
                <MaterialReactTable
                    headers={headerColumns}
                    data={tableData}
                    handleChangePage={nextPage}
                    totalRows={totalRecords}
                    pageNumberToUse={getValues().pageNumber}
                    enableRowActions={false}
                />}
        </Box>
    );
}

const ControllerField = ({
                             control,
                             name,
                             label,
                             rules,
                             type = "text",
                             select = false,
                             options = []
                         }) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        label={label}
                        type={type}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        onBlur={(e) => field.onChange(e.target.value.trim())}
                        select={select}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                    >
                        {select &&
                            options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                    </TextField>
                )}
            />
        </Grid>
    );
};