// creating Router
import {createBrowserRouter, createRoutesFromElements, Route,} from "react-router-dom";

import {LandingPage} from "../pages/home/LandingPage";
import React from "react";
import QuoteSearchPage from "../pages/quote-search/QuoteSearchPage";
import QuoteRequestPage from "../pages/quote-request/QuoteRequestPage";
import QuoteSubmitPage from "../pages/quote-submit/QuoteSubmitPage";
import AppLayoutPage from "../pages/general/AppLayoutPage";
import {createTheme} from "@mui/material";
import Error404 from "../pages/general/Error404";
import ProtectedAppLayoutPage from "../pages/general/ProtectedAppLayoutPage";
import jwt_decode from "jwt-decode";
import CarrierForm from "../pages/carrier-form/CarrierForm";
import VendorRenewalConfigurationForm from "../pages/renewal-config/VendorRenewalConfigurationForm";
import VendorRenewalConfigurationView from "../pages/renewal-config/VendorRenewalConfigurationView";
import AppointmentsSearchPage from '../pages/appointment-override/AppointmentsSearchPage';
import {ProtectedRoute} from "./authentication/ProtectedRoute";
import {NAVIGATION_ITEMS} from "../util/constants";
import UnauthorizedPage from "./authentication/UnauthorizedPage";
import NewCampaign from "../pages/campaign/NewCampaign";
import ThrowAwayQuoteRequestPage from "../pages/throwaway-quotes/ThrowAwayQuoteRequestPage";

const uuidRegex =
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$";

const COMPONENT_MAP = {
    "/quote-search": QuoteSearchPage,
    "/quote-submit": QuoteSubmitPage,
    "/dashboard": QuoteRequestPage,
    "/throw-away-quotes": ThrowAwayQuoteRequestPage,
    "/carrier-form": CarrierForm,
    "/vendor-renewal-view": VendorRenewalConfigurationView,
    "/vendor-renewal-config": VendorRenewalConfigurationForm,
    "/campaign": NewCampaign,
    "/appointments-override-view": AppointmentsSearchPage
};

const appRoutes = Object.keys(NAVIGATION_ITEMS).map((path) => {
    const requiredRoles = NAVIGATION_ITEMS[path];
    const Component = COMPONENT_MAP[path];

    return (
        <Route
            key={path}
            path={path}
            element={
                requiredRoles.length > 0 ?
                    <ProtectedRoute requiredRoles={requiredRoles}>
                        <Component />
                    </ProtectedRoute> :
                    <Component />
            }
        />
    );
});

export const createRouter = ({msalInstance}) =>
    createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<AppLayoutPage/>}>
                <Route index element={<LandingPage/>}/>
                <Route path="/unauthorized" element={<UnauthorizedPage/>}/>
                <Route path="/" element={<ProtectedAppLayoutPage/>}>
                    <Route path="/home" element={<LandingPage/>}/>
                    {appRoutes}
                </Route>
                <Route path="*" element={<Error404/>}/>
            </Route>
        )
    );
export const createCpicTheme = createTheme({
    palette: {
        cpicheaderbackgroundcolor: {
            main: '#0066a1',
        }
    },
});


export const isValidUUID = (val) => {
    if (val.length !== 36) return false;

    val = val.match(uuidRegex);
    return val !== null;

}

export const parseError = (err) => {
    let errorObject = err?.message;
    if (errorObject) {
        try {
            return JSON.parse(errorObject)?.errorMessage + " : " + JSON.parse(errorObject)?.additionalMessage;
        } catch (e) {
            return errorObject;
        }
    } else {
        return 'An error occurred!';
    }
}

export const isValidJSON = (jsonString) => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
};

export const hasTokenExpired = (token) => {
    let decodedToken = jwt_decode(token);
    let dateNow = new Date();
    return decodedToken?.exp < dateNow.getTime();
}