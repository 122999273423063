import React, {useEffect, useState} from "react";
import MaterialReactTable from "material-react-table";
import {Box, IconButton, Tooltip,} from "@mui/material";
import Dialog from "./Dialog";
import {PlayCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
export const CampaignMaterialTable = ({
                                          headers,
                                          data,
                                          handleChangePage,
                                          totalRows,
                                          pageNumberToUse,
                                          enableFilter,
                                          enableSort,
                                          enableRowActions,
                                          manualPagination,
                                          enableEditing,
                                          handleExecuteCampaign
                                      }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [jsonData, setJsonData] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: pageNumberToUse,
    pageSize: 1000,
  });
  const navigate = useNavigate();

  useEffect(() => {
      console.log('data', data)
  }, [data])

  useEffect(() => {
    if (pageNumberToUse !== pagination.pageIndex) {
      handleChangePage(pagination.pageIndex);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
      <div style={{width: "100%"}}>
        <Dialog
            openState={openDialog}
            viewData={selectedRowData}
            handleCloseDialog={handleClose}
            jsonToUse={jsonData}
        />
        <MaterialReactTable
            columns={headers}
            data={data}
            enableRowActions={enableRowActions}
            enableStickyHeader
            isMultiSortEvent={() => false}
            enableSorting={enableSort}
            enableFilters={enableFilter}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions", //change header text
              },
            }}
            positionActionsColumn="last"
            rowCount={totalRows}
            renderRowActions={({row, table}) => (
                <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "2px",
                      width: "5rem",
                    }}
                >
                    {handleExecuteCampaign && (
                        <Tooltip arrow placement="left" title="Execute Campaign">
                            <IconButton
                                color="primary"
                                onClick={() => handleExecuteCampaign(row.original?.campaignId)}
                                disabled={(row?.original?.campaignProcessed)}
                            >
                                <PlayCircle/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            )}
            enableColumnResizing
            enableDensityToggle={false}
            enableRowVirtualization
            muiTableContainerProps={{sx: {maxHeight: "500px"}}}
            enableColumnOrdering
            enablePinning
            manualPagination={manualPagination}
            onPaginationChange={setPagination}
            state={{pagination}}
            muiTablePaginationProps={{
              rowsPerPageOptions: [25],
              showFirstButton: false,
              showLastButton: false,
            }}
            enableEditing={enableEditing}
        />
      </div>
  );
};

export default CampaignMaterialTable;
