import React, {useEffect, useState} from "react";
import SearchCriteria from "./SearchCriteria";
import {parseError} from "../../config/react-routing";
import Loader from "../general/Loader";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import Box from "@mui/material/Box";
import MaterialReactTable from "./MaterialReactTable";
import Swal from "sweetalert2";
import AppService from "../../service/AppService";

export default function QuoteRequestPage(props) {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [error, setError] = useState();
  const [searchForm, setSearchForm] = useState({
    zip: "",
    county: "",
    producerCode: "",
    createdAfter: "",
    createdBefore: "",
    createdAfterDate: "",
    createdBeforeDate: "",
  });
  const {execute, isLoading} = useFetchWithMsal(loginRequest);

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify({}));
  }, []);

  const getDetails = async (criteria) => {
    setError(null);
    let searchCriteria = Object.assign({}, criteria);
    if (searchCriteria.createdAfterDate && searchCriteria.createdBeforeDate) {
      searchCriteria.createdAfter = searchCriteria.createdAfterDate + " 00:00:00.000"
      searchCriteria.createdBefore =
          searchCriteria.createdBeforeDate + " 23:59:59.000"
    }
    delete searchCriteria.createdAfterDate;
    delete searchCriteria.createdBeforeDate;
    AppService().getQuoteSearchResults(
        execute,
        JSON.stringify(searchCriteria)
    )
        .then((data) => {
          setTableData(data?.searchResult);
          setTotalRecords(data?.numOfRecords);
          setPageNumber(data?.pageNumber);
          setSearchForm({
            ...searchForm,
            pageNumber: data?.pageNumber,
            token: data?.token,
          });
        })
        .catch((err) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed to get search results",
            text: parseError(err),
            showConfirmButton: true,
          });
        });
  };

  const handleReset = () => {
    setSearchForm({
      zip: "",
      county: "",
      producerCode: "",
      createdAfter: "",
      createdBefore: "",
      createdAfterDate: "",
      createdBeforeDate: "",
    });
    setTableData([]);
    setTotalRecords(0);
    setPageNumber(0);
    setError(null);
  };

  const nextPage = async (newPage) => {
    setPageNumber(newPage);
    getDetails({...searchForm, pageNumber: newPage});
  };

  const buildAddress = (row) => {
    let result = row?.address1;
    if (row?.address2) {
      result = result + " " + row?.address2;
    }
    return result + " " + row?.city + " " + row?.state + " " + row?.zip;
  };

  const headerColumns = [
    {
      header: "REQUEST ID",
      accessorKey: "id",
      muiTableHeadCellProps: {
        sx: {
          background: "#0066a1",
          color: "#fff",
        },
      },
    },
    {
      header: "PROPERTY ADDRESS",
      accessorKey: "address",
      muiTableHeadCellProps: {
        sx: {
          background: "#0066a1",
          color: "#fff",
        },
      },
      Cell: (rowData) => {
        return <div>{buildAddress(rowData?.row?.original)}</div>;
      },
    },
    {
      header: "PRODUCER CODE",
      accessorKey: "producerCode",
      muiTableHeadCellProps: {
        sx: {
          background: "#0066a1",
          color: "#fff",
        },
      },
    },
    {
      header: "CREATED ON",
      accessorKey: "createdAt",
      muiTableHeadCellProps: {
        sx: {
          background: "#0066a1",
          color: "#fff",
        },
      },
      Cell: ({cell}) => {
        return (
            <div>{new Date(cell.getValue())?.toISOString()?.split("T")[0]}</div>
        );
      },
    },
  ];

  return (
      <Box display="flex" flexDirection="column" overflowY="auto">
        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: "22px",
              fontWeight: 700,
              marginBottom: "1rem",
            }}
        >
          Search Requests:
        </div>
        <SearchCriteria
            handleGetSearchResults={getDetails}
            setRows={setTableData}
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            handleReset={handleReset}
        />
        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
        >
          {isLoading && <Loader/>}
        </div>
        {!error && !isLoading && tableData?.length > 0 && (
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "1rem",
                  marginBottom: "3rem",
                  width: "100%",
                }}
            >
              <MaterialReactTable
                  headers={headerColumns}
                  data={tableData}
                  handleChangePage={nextPage}
                  totalRows={totalRecords}
                  pageNumberToUse={pageNumber}
                  enableRowActions={true}
              />
            </div>
        )}
      </Box>
  );
}
