import React, {useEffect, useState} from "react";
import Loader from "../general/Loader";
import {buildCampaignPieData, centerStyle, layOutStyle, wrapStyle} from "../../util/constants";
import {MultiSelect} from "react-multi-select-component";
import Box from "@mui/material/Box";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Moment from "moment-timezone";
import {SegmentedMessage} from "sms-segments-calculator";
import {
    Button,
    Card,
    CardContent,
    FormControlLabel,
    Grid,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Swal from "sweetalert2";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import {useAccount, useMsal} from "@azure/msal-react";
import AppService from "../../service/AppService";
import CampaignMaterialTable from "./CampaignMaterialReactTable";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FileDownload} from "@mui/icons-material";
import PieChart from "../analytics/pieChart";

const headerColumns = [
    {
        header: "NAME",
        accessorKey: "campaignName",
        minSize: 20,
        maxSize: 50,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        },
    },
    {
        header: "MESSAGE",
        accessorKey: "campaignMessage",
        minSize: 50,
        maxSize: 50,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        },
    },
    {
        header: "Status",
        accessorKey: "campaignStatus",
        minSize: 50,
        maxSize: 50,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        },
    },
    {
        header: "START DATE",
        accessorKey: "startDate",
        size: 50,
        maxSize: 50,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        },
        Cell: ({cell}) => {
            return <div>{Moment(cell.getValue()).format("YYYY-MM-DD")}</div>;
        },
    },
    {
        header: "START TIME",
        accessorKey: "startTime",
        size: 50,
        maxSize: 50,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        },
        Cell: ({cell}) => {
            return <div>{Moment(cell.getValue()).format("hh:mm a")}</div>;
        },
    },
    {
        header: "FILTER BY",
        accessorKey: "filterBy",
        size: 50,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        }
    },
    {
        header: "FILTER VALUES",
        accessorKey: "filterValues",
        size: 350,
        muiTableHeadCellProps: {
            sx: {
                background: "#0066a1",
                color: "#fff",
            },
        },
        Cell: ({cell}) => {
            return <div>{cell.getValue().map((value, index) => (
                <span key={index} style={{
                    display: 'inline-block',
                    padding: '4px 8px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    margin: '2px',
                    backgroundColor: '#f9f9f9'
                }}>
                  {value}
                </span>
            ))}</div>;
        },
    }
];

const searchForm = {
    campaignId: undefined,
    campaignName: "",
    startDate: "",
    startTime: "",
    campaignStatus: "Draft",
    campaignMessage: "",
    selectedFilterItems: [],
    filterBy: "county"
};
const schemaObject = Yup.object().shape({
    campaignName: Yup.string().trim().required(),
    campaignMessage: Yup.string().trim().required(),
    selectedFilterItems: Yup.array().min(1, "At least one filter item must be selected"),
    startDate: Yup.string()
        .trim()
        .required()
        .test("is less than", "start date must be less than end date", (value, ctx) => {
            const currentDate = Moment(Date.now()).format("YYYY-MM-DD");
            return value && value >= currentDate;
        }),
});

export default function NewCampaign() {
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});
    const {execute, isLoading} = useFetchWithMsal(loginRequest);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [segments, setSegments] = useState(0);
    const [filterBy, setFilterBy] = useState("county");
    const [selectedFilterItems, setSelectedFilterItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [msalError, setMsalError] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [optionsZipcode, setOptionsZipcode] = useState([]);
    const [optionsCounty, setOptionsCounty] = useState([]);

    const [campaignDetailsForAnalytics, setCampaignDetailsForAnalytics] = useState([]);
    const [selectCampaignDetailsForAnalytics, setSelectCampaignDetailsForAnalytics] = useState();
    const [pieOptions, setPieOptions] = useState();
    const [pieData, setPieData] = useState();
    const [selectedPieItem, setSelectedPieItem] = useState();

    useEffect(() => {
        console.log('tableData', tableData);
    }, [tableData]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 1) {
            getDetails();
        }
    };

    useEffect(() => {
        console.log('campaignDetailsForAnalytics', campaignDetailsForAnalytics);
    }, [campaignDetailsForAnalytics])

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: searchForm,
        resolver: yupResolver(schemaObject),
    });
    const campaignMessage = watch("campaignMessage");
    const [inProgressRecords, setInProgressRecords] = useState(0);
    const [completedRecords, setCompletedRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [campaignNames, setCampaignNames] = useState([]);
    const [result, setResult] = useState();

    useEffect(() => {
        localStorage.setItem("campaignDetails", JSON.stringify({}));
        if (!result && account) {
            instance
                .acquireTokenSilent({
                    scopes: loginRequest.scopes,
                    account: account,
                })
                .then((result) => {
                    console.log("Result Account Use effect", result);
                    setResult(result);
                })
                .catch((error) => {
                    console.log("Result Error", error.message);
                    setMsalError(setMsalError);
                });
        }
    }, [account, instance]);

    useEffect(() => {
        if (result) {
            getFiltersData();
            getDetails();
        }
    }, [result]);

    const getFiltersData = async () => {
        setLoadingIndicator(true);
        AppService().getCampaignFiltersData(execute).then(response => {
            setLoadingIndicator(false);
            const formattedCounties = response.counties.map((county) => ({
                label: county,
                value: county
            }));
            const formattedZipCodes = response.zipCodes.map((zipCode) => ({
                label: zipCode,
                value: zipCode
            }));
            setOptionsZipcode(formattedZipCodes);
            setOptionsCounty(formattedCounties);
        })
            .catch(reason => {
                console.error(reason);
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to fetch filters data",
                    text: reason.message,
                    showConfirmButton: true,
                });
            })
    }

    const handleCampaignJob = async (selectedCampaignId) => {
        const postObject = Object.assign(
            {},
            {
                campaignId: selectedCampaignId,
            }
        );
        setLoadingIndicator(true);
        await execute(
            "POST",
            `/api/campaign/startJob`,
            JSON.stringify(postObject)
        )
            .then((data) => {
                console.log("Data", data);
                if (data.status === "Success") {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: `Campaign Successfully Started`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (data.status === 'Fail') {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Failed",
                        text: data?.errorMessages?.toString() || "Failed",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Insert Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    useEffect(() => {
        if (campaignMessage) {
            setSegments(new SegmentedMessage(campaignMessage));
        }
    }, [campaignMessage]);

    const handleReset = () => {
        reset({
            ...searchForm,
            selectedFilterItems: [],
            filterBy: "county",
        });
        setSelectedFilterItems([]);
    };

    const handleFilterChange = (event) => {
        setFilterBy(event.target.value);
        setSelectedFilterItems([]);
        setValue("filterBy", event.target.value);
        setValue("selectedFilterItems", []);
    };

    const options = filterBy === "county" ? optionsCounty : optionsZipcode;

    useEffect(() => {
        setSegments(new SegmentedMessage(campaignMessage));
    }, [campaignMessage]);

    const handleSubmitInput = (data) => {
        console.log("Form Data: ", data);
        const postObject = Object.assign(
            {},
            {
                campaignName: data.campaignName,
                campaignMessage: data.campaignMessage,
                startDate: new Date(`${data.startDate} ${data.startTime}`).getTime(),
                campaignStatus: 'Draft',
                operationType: 'create',
                filterBy: data.filterBy,
                filterValues: data.selectedFilterItems.map(pair => pair.value)
            }
        );
        console.log("Submit Data", postObject);
        if (data.campaignId) {
            postObject['campaignId'] = data.campaignId;
        }
        setLoadingIndicator(true);
        AppService().createCampaign(execute, JSON.stringify(postObject))
            .then((data) => {
                console.log("Data", data);
                if (data.status === "Success") {
                    handleReset();
                    getDetails();
                    setLoadingIndicator(false);
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Failed",
                        text: data?.message || "Failed",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Insert Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    const getDetails = () => {
        const payload = {
            limitedData: false,
        };
        setLoadingIndicator(true);

        AppService().getCampaignDetails(execute, JSON.stringify(payload))
            .then((data) => {
                setLoadingIndicator(true);
                console.log("Get Data Campaign Details", data);
                if (data.campaignDetails && data.campaignDetails.length > 0) {
                    let updatedTableData = data.campaignDetails.map((item) => ({
                        ...item,
                        startTime: item.startDate,
                    }));
                    console.log("Get Data Campaign Details", updatedTableData);
                    setTableData(updatedTableData);
                    setTotalRecords(updatedTableData.length);
                    setCompletedRecords(data.completedCampaigns);
                    setInProgressRecords(data.inProgressCampaigns);
                    setTotalRecords(updatedTableData.length);
                    setPageNumber(1);
                    const campainDetails = data.campaignDetails;
                    const campaignArray = [];
                    const campaignArrayAlreadyUploaded = [];

                    if (campainDetails?.length > 0) {
                        campainDetails.forEach((item) => {
                            let objectToUse = {
                                key: item.campaignId,
                                value: item.campaignName,
                            };
                            campaignArray.push(objectToUse);
                            if (item.campaignProcessed) {
                                campaignArrayAlreadyUploaded.push(objectToUse);
                            }
                        });
                    }
                    setCampaignNames(campaignArray);
                    setCampaignDetailsForAnalytics(campaignArrayAlreadyUploaded);
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Campaign Details",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "No campaign details found",
                        text: "No campaign details",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Get Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get campaign details",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    const handleCampaignSelectAnalyticsChange = (event) => {
        console.log('handleCampaignSelectAnalyticsChange', event.target.value);
        setSelectCampaignDetailsForAnalytics(event.target.value);
    }

    const downloadProcessedCampaignFile = async () => {
        console.log('selectCampaignDetailsForAnalytics', selectCampaignDetailsForAnalytics);
        try {
            setLoadingIndicator(true);
            const response = await AppService().downloadProcessedCampaignFile(execute, selectCampaignDetailsForAnalytics);
            if (response) {
                const byteCharacters = atob(response.fileBody);
                const byteNumbers = new Uint8Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const blob = new Blob([byteNumbers], {type: 'application/octet-stream'});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'campaign_processed_file' + Date.now().toString() + '.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "File download is in progress.",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                console.error('Failed to download file');
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center", icon: "error", title: "File not found.", showConfirmButton: false, timer: 1500,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setLoadingIndicator(false);

            Swal.fire({
                position: "center", icon: "error", title: "File not found.", showConfirmButton: false, timer: 1500,
            });
        }
    }


    const getAnalytics = async () => {
        setLoadingIndicator(true);

        AppService().getCampaignSmsStatus(execute, JSON.stringify({campaignId: selectCampaignDetailsForAnalytics}))
            .then((res) => {
                setLoadingIndicator(false);
                setPieData(undefined);
                setSelectedPieItem(undefined);
                const createdPieData = buildCampaignPieData(res);
                console.log("createdPieData", createdPieData);
                setPieData(createdPieData);
                const pieOptions = {
                    onClick: (event, elements) => {
                        if (elements.length > 0) {
                            console.log('elements pie data', elements);
                            const clickedElementIndex = elements[0].index;
                            console.log('analytics[clickedElementIndex]', res[clickedElementIndex])
                            setSelectedPieItem(res[clickedElementIndex]);
                        }
                    },
                };
                setPieOptions(pieOptions);
            })
            .catch((err) => {
                console.error(err);
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "No campaign available",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    return (
        <div>
            {loadingIndicator && (
                <div style={wrapStyle}>
                    <div style={centerStyle}>
                        <Loader/>
                    </div>
                </div>
            )}
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Create Campaign"/>
                <Tab label="View Campaigns"/>
                <Tab label="Analytics"/>
            </Tabs>
            <Box sx={{p: 3}}>
                {tabValue === 0 && <div>
                    {!loadingIndicator && (
                        <div>
                            <div>
                                <div
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        color: "#2C2C2C",
                                        marginBottom: "0.5rem",
                                    }}
                                >
                                    New Campaign Form:
                                </div>
                                <form
                                    onSubmit={handleSubmit(handleSubmitInput)}
                                    onReset={handleReset}
                                    style={{display: "flex", flexDirection: "column", width: "100%"}}
                                >
                                    <Paper
                                        key={"campaignForm"}
                                        variant="elevation8"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "1rem",
                                            marginBottom: "1rem",
                                            width: "98%",
                                        }}
                                    >
                                        <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                            <Grid item xs={12} s={8} md={4} lg={3}>
                                                <Controller
                                                    control={control}
                                                    name={"campaignName"}
                                                    render={({field}) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            label="Name"
                                                            required
                                                            error={!!errors.campaignName}
                                                            helperText={errors.campaignName && "Name is required/invalid"}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} s={8} md={4} lg={3}>
                                                <Controller
                                                    control={control}
                                                    name={"startDate"}
                                                    render={({field}) => (
                                                        <TextField
                                                            type="date"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            {...field}
                                                            label="Start Date"
                                                            required
                                                            error={!!errors.startDate}
                                                            helperText={errors.startDate && "Start Date is required/invalid"}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} s={8} md={4} lg={3}>
                                                <Controller
                                                    control={control}
                                                    name={"startTime"}
                                                    render={({field}) => (
                                                        <TextField
                                                            type="time"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            {...field}
                                                            label="Start Time"
                                                            required
                                                            error={!!errors.startTime}
                                                            helperText={errors.startTime && "Start Date is required/invalid"}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} s={8} md={8} lg={6}>
                                                <Controller
                                                    control={control}
                                                    name={"campaignMessage"}
                                                    render={({field}) => (
                                                        <TextField
                                                            fullWidth
                                                            {...field}
                                                            label="Message"
                                                            required
                                                            multiline
                                                            rows={4}
                                                            error={!!errors.campaignMessage}
                                                            helperText={
                                                                errors.campaignMessage
                                                                    ? "Message is required/invalid"
                                                                    : `Length: ${campaignMessage.length} characters, Segments: ${segments.segmentsCount}`
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            {/* Radio button group for filter selection */}
                                            <Grid item xs={12} md={4} lg={3}>
                                                <Box sx={{marginBottom: "20px"}}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Filter by</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-label="filter-by"
                                                            name="filter-by"
                                                            value={filterBy}
                                                            onChange={handleFilterChange}
                                                        >
                                                            <FormControlLabel value="county" control={<Radio/>}
                                                                              label="County"/>
                                                            <FormControlLabel value="zipcode" control={<Radio/>}
                                                                              label="Zipcode"/>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Box>
                                            </Grid>

                                            {/* Multi-select and Selected Items boxes */}
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" justifyContent="space-between" width="100%"
                                                     marginTop="1rem">
                                                    {/* Left Box */}
                                                    <Box
                                                        sx={{
                                                            flex: 1,
                                                            padding: 2,
                                                            border: "2px solid #ddd",
                                                            borderRadius: "8px",
                                                            marginRight: "1rem",
                                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                                        }}
                                                    >
                                                        <h3>Select {filterBy === "county" ? "Counties" : "Zipcodes"}</h3>
                                                        <MultiSelect
                                                            options={options}
                                                            value={selectedFilterItems}
                                                            onChange={(selectedItems) => {
                                                                setSelectedFilterItems(selectedItems);
                                                                setValue("selectedFilterItems", selectedItems);
                                                            }}
                                                            labelledBy={`Select ${filterBy === "county" ? "County" : "Zipcode"}`}
                                                        />
                                                        {errors.selectedFilterItems && (
                                                            <p style={{color: "red"}}>{errors.selectedFilterItems.message}</p>
                                                        )}
                                                    </Box>

                                                    {/* Right Box */}
                                                    <Box
                                                        sx={{
                                                            width: '300px',
                                                            height: '200px',
                                                            padding: 2,
                                                            border: '2px solid #ddd',
                                                            borderRadius: '8px',
                                                            marginLeft: '1rem',
                                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                            overflowY: 'auto',
                                                            overflowX: 'hidden',
                                                        }}
                                                    >
                                                        <h3>Selected {filterBy === "county" ? "Counties" : "Zipcodes"}</h3>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                gap: '8px',
                                                            }}
                                                        >
                                                            {selectedFilterItems.map((item) => (
                                                                <Box
                                                                    key={item.value}
                                                                    sx={{
                                                                        padding: '8px',
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        backgroundColor: '#f9f9f9',
                                                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                                        minWidth: '80px',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {item.label}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    marginTop="1rem"
                                                    width="100%"
                                                >
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Submit
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{background: "grey", marginLeft: "0.5rem"}}
                                                        type="reset"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </form>

                            </div>
                        </div>
                    )
                    }</div>}
                {
                    tabValue === 1 &&
                    <div>
                        <Box width={"100%"} marginTop="1rem">
                            <CampaignMaterialTable
                                headers={headerColumns}
                                data={tableData}
                                handleChangePage={() => console.log("NextPage")}
                                totalRows={totalRecords}
                                enableFilter={true}
                                enableSort={true}
                                enableRowActions={true}
                                manualPagination={false}
                                enableEditing={false}
                                uploadAction={false}
                                displayAllRows={false}
                                handleExecuteCampaign={handleCampaignJob}
                            />
                        </Box>
                    </div>
                }
                {tabValue === 2 && <div>
                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} alignItems="center">

                        <Grid item xs={12} s={8} md={4} lg={3}>
                            <Box sx={{minWidth: '25rem'}}>
                                <InputLabel sx={{marginBottom: '1rem'}} id={"campaignId"}>Campaign Name</InputLabel>
                                <Select
                                    value={selectCampaignDetailsForAnalytics || ''}
                                    type="select"
                                    label="Campaign Name"
                                    labelId={"campaignId"}
                                    onChange={handleCampaignSelectAnalyticsChange}
                                    sx={{width: '20rem'}}
                                >
                                    {campaignDetailsForAnalytics.map((item, i) => (
                                        <MenuItem value={item.key} name={item.value} key={item.key}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item xs={12} s={4} md={2} lg={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={selectCampaignDetailsForAnalytics === '' || selectCampaignDetailsForAnalytics === undefined}
                                onClick={getAnalytics}
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={12} s={12} md={4} lg={4} container justifyContent="flex-end">
                            <Button
                                onClick={() => downloadProcessedCampaignFile()}
                                startIcon={<FileDownload/>}
                                variant="contained"
                                disabled={selectCampaignDetailsForAnalytics === "" || selectCampaignDetailsForAnalytics === undefined}
                            >
                                Download Campaign Processed File
                            </Button>
                        </Grid>
                        {
                            (pieData && pieOptions) &&
                            < Box style={layOutStyle}>
                                <Grid container>
                                    <Grid item lg={6}>
                                        <PieChart data={pieData}
                                                  options={pieOptions}/>
                                    </Grid>
                                    <Grid item lg={4}>
                                        {selectedPieItem && (
                                            <Card sx={{marginTop: '10rem'}}>
                                                <CardContent>
                                                    <Typography variant="h6">
                                                        {selectedPieItem.countyName
                                                            ? `${selectedPieItem.countyName} - Detailed Records`
                                                            : selectedPieItem.zipCode
                                                                ? `${selectedPieItem.zipCode} - Detailed Records`
                                                                : 'Unknown Location - Detailed Records'}
                                                    </Typography>
                                                    {selectedPieItem.smsStatusAndCountList.map((element)=>(
                                                        <Typography>{element.smsStatus}: {element.smsCount}</Typography>
                                                    ))}
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Grid>
                </div>}
            </Box>
        </div>
    );
}
