import React, {useCallback, useEffect, useState} from "react";
import {Accordion, Box, Button, Modal, styled, Tab, Tabs, TextField, Typography} from "@mui/material";
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import {isValidUUID, parseError} from "../../config/react-routing";
import {ArrowForwardIosSharp} from "@mui/icons-material";
import RatingPayloadTile from "./RatingPayloadTile";
import Loader from "../general/Loader";
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import ReactJson from "react18-json-view";
import "react18-json-view/src/style.css";
import {useNavigate} from "react-router-dom";
import AppService from "../../service/AppService";
import CarrierMenu from "./CarrierMenu";
import QuoteRequestMetadata from "./QuoteRequestMetadata";
import Swal from "sweetalert2";

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{fontSize: "1rem"}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function QuoteSearchPage() {
    const [error, setError] = useState();
    const [requestId, setRequestId] = useState("");
    const [exisingRequestId, setExisingRequestId] = useState("");
    const [requestBody, setRequestBody] = useState();
    const [quoteResponse, setQuoteResponse] = useState();
    const [quoteResponseErrorMessage, setQuoteResponseErrorMessage] = useState();
    const [ratingPayload, setRatingPayload] = useState();
    const [ratingErrorMessage, setRatingErrorMessage] = useState();
    const [expandRequest, setExpandRequest] = useState(false);
    const [expandResponse, setExpandResponse] = useState(false);
    const [expandCarrierRequest, setExpandCarrierRequest] = useState(false);
    const [expandCarrierResponse, setExpandCarrierResponse] = useState(false);
    const [quoteCarrierRequest, setQuoteCarrierRequest] = useState();
    const [quoteCarrierResponse, setQuoteCarrierResponse] = useState();
    const [expandRatingPayload, setExpandRatingPayload] = useState(false);
    const [checkEligibilityShow, setCheckEligibilityShow] = useState(false);
    const [carrierRequestAndResponseData, setCarrierRequestAndResponseData] = useState([]);
    const [overrideEligibilityModal, setOverrideEligibilityModal] = useState(false);
    const {execute, isLoading} = useFetchWithMsal(loginRequest);
    const navigate = useNavigate();
    const epochAttributes = ["dob", "effectiveDate", "expirationDate",
        "yearPurchased", "fourPointInspectionDate", "externalInspectionDate", "requestTimestamp"];
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const toggleAccordion = useCallback((obj) => {
        if (obj.hasOwnProperty("expandRequest")) {
            setExpandRequest(obj["expandRequest"]);
            setExpandResponse(false);
            setExpandRatingPayload(false);
            setExpandCarrierRequest(false);
            setExpandCarrierResponse(false);
        } else if (obj.hasOwnProperty("expandResponse")) {
            setExpandRequest(false);
            setExpandResponse(obj["expandResponse"]);
            setExpandCarrierRequest(false);
            setExpandCarrierResponse(false);
            setExpandRatingPayload(false);
        } else if (obj.hasOwnProperty("expandRatingPayload")) {
            setExpandRequest(false);
            setExpandResponse(false);
            setExpandCarrierRequest(false);
            setExpandCarrierResponse(false);
            setExpandRatingPayload(obj["expandRatingPayload"]);
        } else if (obj.hasOwnProperty("expandCarrierRequest")) {
            setExpandRequest(false);
            setExpandResponse(false);
            setExpandCarrierRequest(obj["expandCarrierRequest"]);
            setExpandCarrierResponse(false);
            setExpandRatingPayload(false);
        } else if (obj.hasOwnProperty("expandCarrierResponse")) {
            setExpandRequest(false);
            setExpandResponse(false);
            setExpandCarrierRequest(false);
            setExpandCarrierResponse(obj["expandCarrierResponse"]);
            setExpandRatingPayload(false);
        }
    }, []);

    const handleSubmit = async () => {
        if (isValidUUID(requestId)) {
            resetAccordian();
            setError(null);
            AppService().getQuoteRequest(
                execute,
                JSON.stringify(Object.assign({}, {requestId: requestId}))
            )
                .then((response) => {
                    if (response) {
                        let requestBody = JSON.parse(response);
                        if (requestBody?.requestId) {
                            setExisingRequestId(requestId);
                            setRequestId("");
                            updateTimestamps(requestBody);
                            setRequestBody(JSON.stringify(requestBody, null, 2));
                            if (requestBody?.policyInfo?.homeOwnersLine?.policyType) {
                                setCheckEligibilityShow(true);
                                const componentState = {
                                    requestId: requestId,
                                    mitRoofCover:
                                        requestBody?.policyInfo?.homeOwnersLine?.dwelling
                                            ?.mitRoofCover || null,
                                    mitOpenProt:
                                        requestBody?.policyInfo?.homeOwnersLine?.dwelling
                                            ?.mitOpenProt || null,
                                    policyType:
                                        requestBody?.policyInfo?.homeOwnersLine?.policyType || null,
                                };
                                localStorage.setItem("items", JSON.stringify(componentState));
                            }
                            fetchCarrierRequestAndResponses(requestBody?.requestId);
                        }
                    } else {
                        setError("Invalid Request Id!");
                        setCheckEligibilityShow(false);
                    }
                })
                .catch((err) => {
                    setCheckEligibilityShow(false);
                    setError(parseError(err));
                });
        } else {
            setError("Not a valid UUID!");
            setCheckEligibilityShow(false);
            resetAccordian();
        }
    };


    function updateTimestamps(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object') {
                    updateTimestamps(obj[key]);
                } else if (epochAttributes.includes(key) && typeof obj[key] === 'number') {
                    obj[key] = epochSecondsToDate(obj[key], key);
                }
            }
        }
    }

    function epochSecondsToDate(epochSeconds, key) {
        if (epochSeconds) {
            const date = new Date(epochSeconds);
            const year = date.getUTCFullYear();
            const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
            const day = ("0" + date.getUTCDate()).slice(-2);
            if ('requestTimestamp' === key) {
                const hours = ("0" + date.getUTCHours()).slice(-2);
                const minutes = ("0" + date.getUTCMinutes()).slice(-2);
                const seconds = ("0" + date.getUTCSeconds()).slice(-2);
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }
            return `${year}-${month}-${day}`;
        }
        return '';
    }

    const fetchCarrierRequestAndResponses = (requestId) => {
        AppService().getCarrierRequestAndResponse(execute, JSON.stringify({requestId: requestId}))
            .then(data => {
                setCarrierRequestAndResponseData(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const resetAccordian = () => {
        setExpandRequest(false);
        setExpandResponse(false);
        setExpandRatingPayload(false);
        setExisingRequestId(null);
        setQuoteResponse(null);
        setQuoteResponseErrorMessage(null);
        setRatingPayload(null);
        setRatingErrorMessage(null);
        setRequestBody(null);
        setExpandCarrierRequest(null);
        setExpandCarrierResponse(null);
    };

    useEffect(() => {
        localStorage.setItem("items", JSON.stringify({}));
    }, []);

    useEffect(() => {
        async function getQuoteResp() {
            if (expandResponse && !quoteResponse) {
                AppService().getQuoteResponse(
                    execute,
                    JSON.stringify(Object.assign({}, {requestId: exisingRequestId}))
                )
                    .then((requestBody) => setQuoteResponse(requestBody))
                    .catch((err) => setError(parseError(err)));
            }
        }

        getQuoteResp();
    }, [expandResponse, quoteResponse, exisingRequestId]);

    useEffect(() => {
        async function getRatingPay() {
            if (expandRatingPayload && !ratingPayload) {
                AppService().getRatingPayload(
                    execute,
                    JSON.stringify(Object.assign({}, {requestId: exisingRequestId}))
                )
                    .then((requestBody) => setRatingPayload(requestBody))
                    .catch((err) => setRatingErrorMessage(parseError(err)));
            }
        }

        getRatingPay();
    }, [expandRatingPayload, ratingPayload, exisingRequestId]);

    useEffect(() => {
        async function getQuoteCarrierResp() {
            if (
                (expandCarrierRequest && !quoteCarrierRequest) ||
                (expandCarrierResponse && !quoteCarrierResponse)
            ) {
                AppService().getQuoteEligibility(
                    execute,
                    JSON.stringify(Object.assign({}, {requestId: exisingRequestId}))
                )
                    .then((carrierObj) => {
                        setQuoteCarrierRequest(carrierObj?.requestObj);
                        setQuoteCarrierResponse(carrierObj?.responseObj);
                    })
                    .catch((err) => setError(parseError(err)));
            }
        }

        getQuoteCarrierResp();
    }, [
        expandCarrierRequest,
        quoteCarrierRequest,
        expandCarrierResponse,
        quoteCarrierResponse,
        exisingRequestId,
    ]);

    const handleCheckEligibility = () => {
        navigate("/carrier-form");
    };

    const overrideEligibilityModalOpen = () => {
        setOverrideEligibilityModal(true);
    }

    const handleOverrideEligibility = () => {
        AppService().handleEligibilityOverride(execute, JSON.stringify({requestId: exisingRequestId})).then((data) => {
            if (data !== null) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: 'Success',
                    text: "Eligibility Override Successful",
                    showConfirmButton: true,
                });
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: 'Failed to Override Eligibility',
                    text: data.errorMessage,
                    showConfirmButton: true,
                });
            }
        }).catch((err) => {
            console.log("Get Error", err.message);
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Failed to Override Eligibility",
                text: err.message,
                showConfirmButton: true,
            });
        }).finally(() => {
            setOverrideEligibilityModal(false);
            fetchCarrierRequestAndResponses(exisingRequestId)}
        );
    }

    const handleCloseOverrideEligibility = () => {
        setOverrideEligibilityModal(false);
    }

    const overrideButtonStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Box sx={{width: "100%"}}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        fontSize: "22px",
                        fontWeight: 700,
                        marginBottom: "1rem",
                    }}
                >
                    Quote Search:
                </div>
                <TextField
                    name="requestId"
                    id="outlined-basic"
                    label="Request Id"
                    variant="outlined"
                    required
                    error={error}
                    value={requestId}
                    onChange={(event) => setRequestId(event.target.value)}
                    size="small"
                    helperText={error}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{marginLeft: "1rem"}}
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Pull
                </Button>
                {checkEligibilityShow && exisingRequestId !== "" && (
                    <Button
                        variant="contained"
                        style={{background: "green", color: "#fff", marginLeft: "1rem"}}
                        onClick={handleCheckEligibility}
                        disabled={isLoading}
                    >
                        Check Eligibility
                    </Button>
                )}
                {requestBody && <p>{requestBody[0]?.requestId}</p>}
            </Box>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                }}
            >
                {isLoading && <Loader/>}
            </div>

            {!isLoading && requestBody && (
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            fontSize: "20px",
                            fontWeight: 700,
                            marginBottom: "0.5rem",
                        }}
                    >
                        {`Request Id: ${exisingRequestId}`}
                    </div>
                    <Modal
                        open={overrideEligibilityModal}
                        onClose={handleCloseOverrideEligibility}
                    >
                        <Box sx={overrideButtonStyle}>
                            <Box>
                                <Typography variant="subtitle1" component="h2">
                                    Are you sure you want to override the original eligibility status for this risk?
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                    <Button variant="contained" color="primary" onClick={handleOverrideEligibility}>
                                        Yes
                                    </Button>
                                    <Button variant="contained" sx={{bgcolor: 'red', '&:hover': {bgcolor: 'darkred'}}}
                                            onClick={handleCloseOverrideEligibility}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    <Box sx={{width: '100%'}}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Quote Request"/>
                            <Tab label="Quote Response"/>
                            <Tab label="Eligibility"/>
                            <Tab label="Rating Payload"/>
                        </Tabs>
                        {tabValue === 0 && <div>
                            <QuoteRequestMetadata requestBody={requestBody}/>
                            <Accordion expanded={expandRequest}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => toggleAccordion({expandRequest: !expandRequest})}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "0.5rem",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Request
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{border: "2px solid #0066a1"}}>
                                    <Typography>
                                        <ReactJson src={JSON.parse(requestBody)}/>
                                        {/* <QuoteRequestTile request={requestBody}/> */}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion></div>}
                        {tabValue === 1 && <div><Accordion expanded={expandResponse}>
                            <AccordionSummary
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                onClick={() =>
                                    toggleAccordion({expandResponse: !expandResponse})
                                }
                            >
                                <Typography variant="h7" gutterBottom align="left">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "0.5rem",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Response
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{border: "2px solid #0066a1"}}>
                                <Typography>
                                    {!quoteResponseErrorMessage && quoteResponse?.length > 0 && (
                                        <ReactJson src={quoteResponse}/>
                                    )}
                                    {quoteResponseErrorMessage && (
                                        <ReactJson src={quoteResponseErrorMessage}/>
                                    )}
                                </Typography>
                            </AccordionDetails>
                        </Accordion></div>}
                        {tabValue === 2 && <div>
                            <div><CarrierMenu data={carrierRequestAndResponseData}
                                              handleOverrideEligibility={overrideEligibilityModalOpen}/>
                                <Accordion expanded={expandCarrierRequest}>
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() =>
                                            toggleAccordion({expandCarrierRequest: !expandCarrierRequest})
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "0.5rem",
                                                fontSize: "18px",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Carrier Request
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{border: "2px solid #0066a1"}}>
                                        <Typography>
                                            <ReactJson src={quoteCarrierRequest}/>
                                            {/* <QuoteRequestTile request={requestBody}/> */}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </div>}
                        {tabValue === 3 && <Accordion expanded={expandRatingPayload}>
                            <AccordionSummary
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                onClick={() =>
                                    toggleAccordion({expandRatingPayload: !expandRatingPayload})
                                }
                            >
                                <Typography variant="h7" gutterBottom align="left">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "0.5rem",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Rating Payload
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{border: "2px solid #0066a1"}}>
                                <Typography>
                                    <RatingPayloadTile
                                        ratingPayload={ratingPayload}
                                        ratingErrorMessage={ratingErrorMessage}
                                    />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>}
                    </Box>
                </>
            )}
            <br/>
            <br/>
            <br/>
        </>
    );
}