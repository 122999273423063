import React, {createContext, useContext, useEffect, useState} from 'react';
import {loginRequest} from "./authConfig";
import useFetchWithMsal from "./useFetchWithMsal";
import AppService from "../../service/AppService";
import {useMsal} from "@azure/msal-react";

export const RoleContext = createContext();

export const useRoles = () => {
    return useContext(RoleContext);
};

export const RoleProvider = ({children}) => {
    const [roles, setRoles] = useState([]);
    const { instance } = useMsal();
    const {execute, isTokenAvailable} = useFetchWithMsal(loginRequest);

    useEffect(() => {
        // Fetch roles and set them in the context
        if (isTokenAvailable) {
            AppService().fetchUserRole(execute).then(setRoles)
                .catch((error) => {
                    console.log(error);
                    instance.logoutRedirect();
                });
        }
    }, [isTokenAvailable]);

    return (
        <RoleContext.Provider value={roles}>
            {children}
        </RoleContext.Provider>
    );
};